import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/section/header"

export default function Services({ pageContext: { data }, location }) {
  const service = data.frontmatter
  const coverImage = service?.image?.childImageSharp?.fixed

  return (
    <Layout>
      <Seo
        title={service.title}
        description={service.description}
        pathname={location.pathname}
        meta={[
          {
            property: "og:type",
            content: "article",
          },
        ].concat(
          coverImage
            ? [
                {
                  property: "og:image",
                  content: "https://www.tonywomersley.com" + coverImage.srcWebp,
                },
                {
                  property: "og:image:width",
                  content: coverImage.width,
                },
                {
                  property: "og:image:height",
                  content: coverImage.height,
                },
              ]
            : [
                {
                  property: "og:image",
                  content: "https://www.tonywomersley.com" + service?.image,
                },
                {
                  property: "og:image:width",
                  content: 1200,
                },
                {
                  property: "og:image:height",
                  content: 600,
                },
              ]
        )}
      />
      <Header title={service?.title} />
      {/* <section className="max-w-[88rem] w-11/12 mx-auto border-b border-secondary/10 py-6 md:py-12 2xl:w-full flex flex-col gap-10">
        <h1 className="text-center font-serif max-w-4xl mx-auto text-2xl md:text-4xl">
          {service.title}
        </h1>

        {service.publish_date && (
          <h3 className="text-center max-w-md mx-auto w-full text-sm fontlight italic text-stone-400">
            {format(parseISO(service.publish_date), "yyyy/MM/dd")}
          </h3>
        )}
      </section> */}
      <section className="grid md:grid-cols-2 pt-12 gap-12 max-w-7xl mx-auto w-11/12">
        <div>
          {service?.image?.childImageSharp ? (
            <GatsbyImage
              image={service.image.childImageSharp.gatsbyImageData}
              className="h-[34rem] object-cover"
              alt={service.title}
            />
          ) : (
            <img
              src={service.image}
              className="h-[34rem] object-cover"
              alt={service.title}
            />
          )}
        </div>
        <div>
          <h2 className="font-serif text-xl leading-9">
            {service.description}
          </h2>
        </div>
      </section>
      <div className="pt-12 flex justify-center mb-5">
        {service.link && (
          <a
            href={service.link}
            target="_blank"
            rel="noreferrer"
            className="px-8 py-3 bg-secondary text-primary uppercase trakcing-widest text-xs"
          >
            Book Now
          </a>
        )}
      </div>
      <Content data={data.html} />
    </Layout>
  )
}

const Content = ({ data }) => {
  return (
    <section
      className="max-w-4xl  w-11/12 2xl:w-full text-black mx-auto py-6 flex flex-col text-left prose md:prose-lg prose-h1:text-3xl prose-h1:font-serif"
      dangerouslySetInnerHTML={{ __html: data }}
    />
  )
}
